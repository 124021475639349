export const Maxlength = {
    plan: {
        name: 100,
        description: 2100,
        no_user: 5,
        no_branch: 5,
        price: 10,
        no_of: 3,
    },
    calendar: {
        name: 100,
        description: 2100,
        // no_user: 5,
        // price: 10,
        // no_of: 5,
    },
    worktype: {
        title: 50,
    },
    domain: {
        title: 50,
    },
    collegeDepartment: {
        name: 50,
    },
    universities: {
        title: 50,
    },
    groupcategorytype: {
        title: 50,
    },
    announcement: {
        message: 255,
    },
    version: {
        version: 10,
    },
    programme: {
        title: 50,
    },
    companytype: {
        title: 100,
    },
    companysize: {
        title: 100,
        from: 50,
        to: 50,
    },
    price: {
        title: 100,
        from: 50,
        to: 50,
    },
    priority: {
        title: 50,
    },

    currencytype: {
        title: 100,
        symbol: 10,
    },

    socialplatform: {
        name: 100,
        url: 200,
    },

    resourcetype: {
        title: 50,
    },

    country: {
        name: 100,
        countryCode: 15,
        isocode: 15,
    },
    state: {
        name: 100,
        countryName: 100,
    },
    stream: {
        name: 100,
        countryName: 100,
    },
    city: {
        name: 100,
    },
    discipline: {
        name: 100,
    },
    industry: {
        title: 150,
        code: 10,
    },
    reportReason: {
        title: 150,
    },
    notification: {
        name: 200,
        notificationType: 250,
        title: 100,
        message: 300,
    },
    gac: {
        name: 100,
    },
    skills: {
        title: 70,
    },
    tags: {
        title: 70,
    },
    designations: {
        title: 70,
    },
    availability: {
        title: 30,
    },
    experience: {
        title: 30,
    },
    language: {
        title: 70,
    },
    discount: {
        percentage: 3,
        amount: 10,
        noOfUsers: 10,
    },
    company: {
        company_name: 100,
        email: 100,
        address: 300,
        mobile: 20,
        cp_email: 100,
        cp_name: 100,
    },
    user: {
        name: 100,
        email: 100,
        address1: 300,
        mobile: 20,
    },
    profile: {
        SocialLink: 200,
        platform: 100,
    },
    role: {
        name: 50,
    },
    admin_profile: {
        name: 100,
        email: 100,
        address1: 300,
        oldPassword: 50,
        newPassword: 50,
        confirmPassword: 50,
        mobile: 20,
    },
    cms: {
        title: 100,
    },
    login: {
        email: 100,
        password: 50,
    },
    forgot: {
        email: 100,
        password: 50,
        confirmpassword: 50,
    },
    rules: {
        title: 50
    },
    pricingtypes: {
        type: 50
    },
    platform_ratting: {
        platform: 25,
    },
    educations: {
        title: 100,
    },
};
